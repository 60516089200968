$(document).on('turbolinks:load', function() {
  if ($('#reservation_calendar').length > 0 || $('#raffle_calendar').length > 0) {
    const AJAX_STATUS_NONE = 0;           // 初期状態 (FullCalendarのevents関数が起動される前 / ajax / render timeoutが動作していない)
    const AJAX_STATUS_INPROGRESS = 1;     // ajaxが動作中 (1回目) この状態でajaxコールバック復帰したらcallbackにてカレンダー描画できる
    const AJAX_STATUS_RETRIGGER = 2;      // ajaxが動作中 (ajax 1回目動作中にevents関数が起動された) renderにてカレンダー描画の必要がある
    const AJAX_STATUS_WAIT_TO_RENDER = 3; // AJAX_STATUS_RETRIGGERのajaxコールバック関数発火後 renderによる描画待ち

    window.g_calendar_events = {
      ajax: {
        // events関数のajax状態
        status: AJAX_STATUS_NONE,
        // ajax動作中に追加起動されたevents関数のajax起動用data
        data_to_render: null,
        // render起動タイマID
        render_timeout_id: 0
      },

      // 動作確認用ログ
      log: {
        ajax: [],
        events: [],
      },

      debug: false,

      fullcalendar_events_handler: function (calendar_el, events_url, start, end, timezone, callback) {
        const get_id = () => Date.now();
        const console_log = (id, textStatus, message) => console.log("event_ajax" + ((textStatus != null) ? (":" + textStatus) : "") + "[" + id + "]: " + message);
        const ajax_log = (id, textStatus, _this, jqXHR, errorThrown = null) => {
          const log_data = {id: id, textStatus: textStatus, this: _this, jqXHR: jqXHR};
          if (errorThrown != null) {
            log_data.errorThrown = errorThrown;
          }
          g_calendar_events.log.ajax.push(log_data);
        };
        const events_log = (id, events, message) => g_calendar_events.log.events.push({id: id, events: events, message: message});

        const render_events = function (events, ajax_id) {
          const id = get_id();
          console_log(id, "render", "done " + ajax_id);
          if (g_calendar_events.debug) events_log(id, events, "render");

          g_calendar_events.ajax.status = AJAX_STATUS_NONE;
          g_calendar_events.ajax.render_timeout_id = 0;
          // ajax.data_to_renderは常にnull
          calendar_el.fullCalendar('renderEvents', events);
        };

        const ajax_error_handler = function (jqXHR, textStatus, errorThrown) {
          const id = get_id();
          console_log(id, textStatus, "url: " + this.url);
          ajax_log(id, textStatus, this, jqXHR, errorThrown);

          // 状態が不明のため全メンバ初期化
          g_calendar_events.ajax = { status: AJAX_STATUS_NONE, data_to_render: null, render_timeout_id: 0 };
        };

        const ajax_complete_handler = function (jqXHR, textStatus) {
          const id = get_id();
          // responseText等もjqXHRに入っており多くのメモリを使用しそうであること、complete(success/error両方完了)時にログするメリットがないことにより、コメントアウトする。
          // ajax_log(id, textStatus, this, jqXHR);
        };

        const ajax_success_handler = function (events, textStatus, jqXHR) {
          const id = get_id();
          const ajax = g_calendar_events.ajax;
          // ajax起動からこのコールバックまでにevents関数呼出があった時、最新のevents関数呼出時のdata
          const data = ajax.data_to_render;

          // responseText等もjqXHRに入っており多くのメモリを使用しそうであること、success時にログするメリットがないことにより、デバッグ時のみログする
          if (g_calendar_events.debug) ajax_log(id, textStatus, this, jqXHR);

          switch (ajax.status) {
            case AJAX_STATUS_INPROGRESS:
              // 1回目のajaxコールバック
              if (data == null) {
                // 追加のevents関数呼出がなかったので、callbackでカレンダー描画する
                ajax.status = AJAX_STATUS_NONE;
                console_log(id, textStatus, "done render callback");
                if (g_calendar_events.debug) events_log(id, events, "none: render callback");
                callback(events);
              } else {
                // 追加のevents関数呼出があったので、最新の取得用dataにて再度ajax起動する
                //  ここで起動したajaxのコールバックではrenderによるカレンダー描画が必要
                ajax.status = AJAX_STATUS_RETRIGGER;
                ajax.data_to_render = null;
                if (g_calendar_events.debug) console_log(id, textStatus, "first retrigger: " + JSON.stringify(data));
                callback([]);
                trigger_event_ajax(data);
              }
              break;
            case AJAX_STATUS_RETRIGGER:
              // 2回目以降のajaxコールバック
              if (data == null) {
                // 追加のevents関数呼出がなかったので、時間待ち後にrenderにてカレンダー描画
                ajax.status = AJAX_STATUS_WAIT_TO_RENDER;
                let wait_msec = 1000;
                if (g_calendar_events.debug) console_log(id, textStatus, "wait_to_render");
                callback([]);
                ajax.render_timeout_id = setTimeout(render_events, wait_msec, events, id);
              } else {
                // 追加のevents関数呼出があったので、最新の取得用dataにて再度ajax起動する
                //  ここで起動したajaxのコールバックではrenderによるカレンダー描画が必要
                ajax.data_to_render = null;
                if (g_calendar_events.debug) console_log(id, textStatus, "retrigger: " + JSON.stringify(data));
                callback([]);
                trigger_event_ajax(data);
              }
              break;
            default:
              console_log(id, textStatus, "invalid ajax callback");
              ajax_log(id, textStatus, this, jqXHR);
              events_log(id, events, "success invalid ajax callback");
              break;
          }
        };

        const trigger_event_ajax = function (ajax_data) {
          $.ajax({
            type: 'GET',
            // A URL of a JSON feed that the calendar will fetch Event Objects from.
            url: events_url,
            dataType: 'json',
            data: ajax_data,
            cache: true,
            success: ajax_success_handler,
            error: ajax_error_handler,
            complete: ajax_complete_handler
          });
        };

        const id = get_id();
        const facility_id = $('#facility_id').val();
        const room_id = $('#room_id').val();
        const user_id = $('#user_id').val();
        const requested_setting_id = $('#requested_setting_id').val();

        // events関数のメインルーチン

        // ajax起動data
        let data = {
          start: start.format(),
          end: end.format()
        }
        if (facility_id) {
          data['facility_id'] = facility_id;
        }
        if (room_id) {
          data['room_id'] = room_id;
        }
        if (user_id) {
          data['user_id'] = user_id;
        }
        if (requested_setting_id) {
          data['requested_setting_id'] = requested_setting_id;
        }

        const ajax = g_calendar_events.ajax;
        // 起動された時の状態
        switch (ajax.status) {
          case AJAX_STATUS_NONE:
            ajax.status = AJAX_STATUS_INPROGRESS;
            ajax.data_to_render = null;
            ajax.render_timeout_id = 0;
            // 1回目のajax起動
            if (g_calendar_events.debug) console_log(id, null, "none: trigger " + JSON.stringify(data));
            trigger_event_ajax(data);
            break;
          case AJAX_STATUS_INPROGRESS:
            // 1回目のajax動作中
            //  追加のevents関数が起動されたので、ajax用dataを保存(このdataの結果をrenderする)
            ajax.data_to_render = data;
            if (g_calendar_events.debug) console_log(id, null, "inprogress: " + JSON.stringify(data));
            // ajax起動しないので、ここでcallback呼出
            callback([]);
            break;
          case AJAX_STATUS_RETRIGGER:
            // 2回目以降のajax動作中
            //  追加のevents関数が起動されたので、ajax用dataを保存(このdataの結果をrenderする)
            ajax.data_to_render = data;
            if (g_calendar_events.debug) console_log(id, null, "retrigger: " + JSON.stringify(data));
            // ajax起動しないので、ここでcallback呼出
            callback([]);
            break;
          case AJAX_STATUS_WAIT_TO_RENDER:
            // render待ち中
            // 待ちを解除し、ajaxを再起動
            const timeout_id = ajax.render_timeout_id;
            ajax.status = AJAX_STATUS_RETRIGGER;
            ajax.data_to_render = null;
            ajax.render_timeout_id = 0;
            clearTimeout(timeout_id);
            if (g_calendar_events.debug) console_log(id, null, "wait_to_render: trigger " + JSON.stringify(data));
            trigger_event_ajax(data);
            break;
          default:
            console_log(id, null, "invalid ajax status " + ajax.status + ": " + JSON.stringify(data));
            ajax.status = AJAX_STATUS_NONE;
            ajax.data_to_render = null;
            ajax.render_timeout_id = 0;
            // ajax起動しないので、ここでcallback呼出
            callback([]);
        }
      }
    };
  } // ($('#reservation_calendar').length > 0 || $('#raffle_calendar').length > 0)

  var events_url = location.pathname.replace(/calendar$/, 'events.json')
  var query = location.search
  if (query.includes('summarize')) {
    let p = new URLSearchParams(query);
    let v = p.get('summarize');
    events_url += '?summarize=' + v;
  }

  $('#reservation_calendar').empty()

  const params = calendar_base_params()

  params.header.right = 'month agendaWeek'
  var add_date = $('#reservation_add_date').val();
  if (add_date) {
    if (this.location.pathname.match(/reservation_calendar/)) {
      let d = new Date(add_date);
      let t = new Date();
      if (d < t) {
        add_date = t.getFullYear() + '-' + ('00' + (t.getMonth()+1)).slice(-2) + '-' + ('00' + t.getDate()).slice(-2);
      }
    }
    params.defaultDate = add_date;
  }

  const calendar_el = $('#reservation_calendar');
  Object.assign(params, {
    // The initial view when the calendar loads.
    defaultView: 'month',
    //events (as a function)
    events: function(start, end, timezone, callback) {
      g_calendar_events.fullcalendar_events_handler(calendar_el, events_url, start, end, timezone, callback);
    },

    //===== Date Clicking & Selecting =====
    // Detect when the user clicks on dates or times. Give the user the ability to select multiple dates or time slots with their mouse or touch device.

    // Allows a user to highlight multiple days or timeslots by clicking and dragging.
    selectable: true,

    // Whether to draw a “placeholder” event while the user is dragging.
    //selectMirror:

    // Whether clicking elsewhere on the page will cause the current selection to be cleared.
    unselectAuto: true,

    // A way to specify elements that will ignore the unselectAuto option.
    unselectCancel: '',

    //----- CALLBACKS -----
    // Triggered when the user clicks on a date or a time.
    //dateClick:

    // Triggered when a date/time selection is made.
    //select: function(start, end, allDay) {
    //  console.log('select called: start=', start, ', end=', end, ', allDay=', allDay)
    //},

    // Triggered when the current selection is cleared.
    //unselect:

    //===== Event Sources =====

    // An array of Event Objects that will be displayed on the calendar.
    //events (as an array)

    // A custom function for programmatically generating Events.
    //events (as a function)

    //===== Event Clicking & Hovering =====
    // Handers to know when an event has been clicked or hovered over.

    // Triggered when the user clicks an event.
    eventClick: function(calEvent, jsEvent, view) {
      // console.log('eventClick called: calEvent=', calEvent, ', jsEvent=', jsEvent)
      // console.log(calEvent)
      // console.log(jsEvent)
      // console.log(view)
      
      type_array = ['raffle_apply', 'raffle_impossible', 'raffle_waiting', 'raffle_ineligible']
      post_type = ['raffle_apply', 'raffle_impossible', 'raffle_waiting']
      delete_type = ['raffle_ineligible']

      if (calEvent.type && post_type.includes(calEvent.type)){
        method_type = 'POST'
      } else if (calEvent.type && delete_type.includes(calEvent.type)){
        method_type = 'DELETE'
      }
      if (calEvent.type && type_array.includes(calEvent.type)) {
        $.ajax({
          type: method_type,
          url: calEvent.url,
          dataType: 'json',
          data: {
            type: calEvent.type
          }
        }).then(
          function(data) {
            console.log('OK: create event:', data)
            $('#reservation_calendar').fullCalendar('refetchEvents')
          },
          function(data) {
            if (data.status == 200) {
              eval(data.responseText)
              console.log('Confirm: create event')
            } else{
              console.log('NG: create event')
            }
          }
        )
        return false
      }
      if (this.href.indexOf('/reservations/new') >= 0) {
        this.href += RESERVATION_NEW_ENTRY_PARAM;
      }
    },

    //===== International =====
    eventColor: 'rgba(150,150,150,0.2)',
    disableDragging: true
  })

  calendar = $('#reservation_calendar').fullCalendar(params)
  $('[data-refetch-trigger]').on('change' , function(){
    calendar.fullCalendar('refetchEvents');
  });

  {
    let application_starts_at_modal = $('#raffle_application_starts_at_modal');
    let application_ends_at_modal = $('#raffle_application_ends_at_modal');

    if (application_starts_at_modal && application_ends_at_modal) {
      let raffle_ineligible_notice = function(form, notice) {
        if ($('#user_id').val() == 'all') {
          var data = {ajax_method: "check_overlapped"};
          form.find('[name]').each(function(index) {
            var name = $(this).prop('name');
            var val = $(this).val();
            data[name] = val;
          });
          $.ajax({
            url: $("#form_action_raffle_ineligible").val(),
            type: 'POST',
            data: data
          }).done(function(data) {
            if (!(data instanceof Object) || (data.result == undefined)) {
              return;
            }
            if (data.result) {
              notice.show();
            } else {
              notice.hide();
            }
          });
        }
      }

      application_starts_at_modal.on('change', function() {
        raffle_ineligible_notice($('#raffle_application_modal_form'), $('#raffle_application_modal_overlapped'));
      });
      application_ends_at_modal.on('change', function() {
        raffle_ineligible_notice($('#raffle_application_modal_form'), $('#raffle_application_modal_overlapped'));
      });
    }
  }

  $("#room_id_in_facility").on('change', function room_select() {
    var date = $('#reservation_calendar').fullCalendar('getDate'); // このdateはMoment.jsのmoment object
    add_date = date.format('YYYY-MM-DD');
    var url = this.options[this.selectedIndex].value;
    url += url.includes('?') ? '&' : '?';
    url += 'add_date=' + add_date;
    self.location = url;
  });
})
